import React, { useRef, useEffect } from "react";
import { tokenInfo, defaultToken } from "../../data/tokens";
import APY from "../../widgets/APY";
import TokenPrice from "../../widgets/TokenPrice";
import TokenRequestEmbedded from "../../TokenRequestEmbedded";
import TokenRequestController from "../../TokenRequestController";
import ProductRequestEmbedded from "./ProductRequestEmbedded";
import AddToMetaMask from "../../AddToMetaMask";

function ProductEmpty(props) {
  const { tokenImg, tvl } = props;

  return (
    <div
      data-delay="0"
      data-hover="false"
      data-w-id="24912147-7b8b-c001-de38-85b386ac799f"
      style={{ height: "140px" }}
      className="faq-p mobi w-dropdown"
    >
      <div
        data-w-id="24912147-7b8b-c001-de38-85b386ac79a0"
        className="faq w-dropdown-toggle"
      >
        <div className="div-block-13 new">
          <div className="product-empty">
            <img src={tokenImg} loading="lazy" alt="" className="image-14" />
            <div className="div-block-58">
              <div className="text-block-3">SOON</div>
            </div>
          </div>
          <div className="div-block-14">
            <div className="div-block-15 mobile">
              <div className="p2-regular-14-24 product">
                Total Value Locked (TVL)
              </div>
              <h1 className="h2---44-52 prody">$ {tvl}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(ProductEmpty);
