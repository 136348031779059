import {configureStore} from "@reduxjs/toolkit";
import customersReducer from "./reducers/customersSlice";
import agentsReducer from "./reducers/agentsSlice";
import officesReducer from "./reducers/officesSlice";
import { combineReducers } from 'redux'

const store = configureStore({
    reducer: combineReducers({
        customers: customersReducer,
        agents: agentsReducer,
        offices: officesReducer
    })
})
export default store;