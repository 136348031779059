import React, { useRef, useEffect } from "react";
import { tokenInfo, defaultToken } from "../../data/tokens";
import APY from "../../widgets/APY";
import TokenPrice from "../../widgets/TokenPrice";
import TokenRequestEmbedded from "../../TokenRequestEmbedded";
import TokenRequestController from "../../TokenRequestController";
import ProductRequestEmbedded from "./ProductRequestEmbedded";
import AddToMetaMask from "../../AddToMetaMask";

function Product(props) {
  const { tokenName, connectWeb3, web3Global } = props;

  const {
    address,
    name,
    image,
    details,
    display_short_description,
    risk,
    symbol,
  } = tokenInfo[tokenName];

  // useEffect(() => {
  //   window.Webflow && window.Webflow.destroy();
  //   window.Webflow && window.Webflow.ready();
  //   window.Webflow && window.Webflow.require("ix2").init();
  //   document.dispatchEvent(new Event("readystatechange"));
  // });

  return (
    <>
      <div
        data-delay="0"
        data-hover="false"
        data-w-id="24912147-7b8b-c001-de38-85b386ac799f"
        // style={{"height":"140px"}}
        className="faq-p mobi w-dropdown"
      >
        <div
          data-w-id="24912147-7b8b-c001-de38-85b386ac79a0"
          className="faq w-dropdown-toggle"
        >
          <div className="div-block-13 new">
            <img src={image} loading="lazy" alt="" className="image-14" />
            <div className="div-block-14">
              <div className="div-block-15 mobile">
                <div className="p2-regular-14-24 product">
                  Total Value Locked (TVL)
                </div>
                <h1 className="h2---44-52 prody">
                  <TokenPrice tokenAddress={address} />
                </h1>
              </div>
              <div className="div-block-15 mobile">
                <div className="p2-regular-14-24 product">
                  Annual Percentage Yield
                </div>
                <h1 className="h2---44-52 annual prdddy">
                  <APY tokenAddress={address} decimals={0} />
                </h1>
              </div>
            </div>
          </div>
          <img
            src="images/Frame-72539.svg"
            loading="lazy"
            alt=""
            className="close"
          />
        </div>
        <nav className="faq-body w-dropdown-list">
          <div className="faq-body-cont">
            <div className="div-block-16">
              <div className="prod-div">
                <div className="p2-regular-14-24 prod">Investment strategy</div>
                <h1 className="h4-bold---24-32 prod">{name}</h1>
                <p className="p1-regular-16-24">{display_short_description}</p>
                <div className="token-contract">
                  <div>{symbol} token</div>
                  <AddToMetaMask
                    connectWeb3={connectWeb3}
                    tokenInfo={tokenInfo[tokenName]}
                  />
                </div>
              </div>
              <div className="prod-div">
                <div className="p2-regular-14-24 prod">Details</div>
                <ul role="list" className="list">
                  {details.map((item, index) => {
                    return (
                      <li className="list-item" key={index}>
                        <p className="p1-regular-16-24">{item}</p>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="prod-div">
                <div className="p2-regular-14-24 prod">Risk</div>
                <ul role="list" className="list">
                  {risk.map((item, index) => {
                    return (
                      <li className="list-item" key={index}>
                        <p className="p1-regular-16-24">{item}</p>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="div-block-17"></div>
            <TokenRequestController
              initialToken={tokenName}
              initialCurrency={"usdt"}
              connectWeb3={connectWeb3}
              web3Global={web3Global}
              address={address}
              Component={ProductRequestEmbedded}
            />
          </div>
        </nav>
      </div>
    </>
  );
}

export default React.memo(Product);
