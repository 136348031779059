import React from "react";

class Title extends React.Component {
  render() {
    return (
      <section id="how" className="section wf-section">
        <h1 className="h2---44-52 head white">HOW IT WORKS</h1>
        <div className="div-block-21">
          <div
            data-w-id="ffbeab84-5783-9fcb-157f-2aaafc19edbd"
            className="howit"
          >
            <div className="div-block-23">
              <h1 className="h1---52-64 yellow">01</h1>
              <div className="p1-bold-16-24 yellow">Choose your strategy</div>
            </div>
            <p className="p1-regular-16-24 white">
              Build your portfolio with different allocation strategies that aim
              to maximize your returns and keep you in your comfortable risk
              zone.
            </p>
          </div>
          <div
            data-w-id="3c46b57c-fc21-1f20-14b2-f6d97030f765"
            className="howit"
          >
            <div className="div-block-23">
              <h1 className="h1---52-64 yellow">02</h1>
              <div className="p1-bold-16-24 yellow">
                Deposit your stablecoin
              </div>
            </div>
            <p className="p1-regular-16-24 white">
              Market opportunities and sentiments continuously monitored via
              oracles, bots and other tools. Portfolio reallocation-
              semi-automatic to avoid potential market data violations and
              hacks.
            </p>
          </div>
          <div
            data-w-id="e277b604-4495-87dc-e489-61a1c8ed1a80"
            className="howit"
          >
            <div className="div-block-23">
              <h1 className="h1---52-64 yellow">03</h1>
              <div className="p1-bold-16-24 yellow">Watch Rebalancing</div>
            </div>
            <p className="p1-regular-16-24 white">
              Just deposit and relax. Your funds will be automatically allocated
              across DeFi protocols and you will immediately start earning
              interest.
            </p>
          </div>
          <div
            data-w-id="86188cc7-46f1-b98e-5f64-d5981b10343e"
            className="howit"
          >
            <div className="div-block-23">
              <h1 className="h1---52-64 yellow">04</h1>
              <div className="p1-bold-16-24 yellow">Redeem Instantly</div>
            </div>
            <p className="p1-regular-16-24 white">
              Monitor your funds&#x27; performance and rebalance events, see
              your estimated earnings and easlily redeem your funds + interest.
            </p>
          </div>
        </div>
        <div className="div-block-24">
          <a href="#products" className="button main w-inline-block">
            <div className="buton-text">JOIN DAO</div>
            <img src={"images/cil_arrow-right.svg"} loading="lazy" alt="" />
          </a>
          <div className="div-block-46">
            <img
              src={"./images/yellowasterisk.svg"}
              loading="lazy"
              style={{ opacity: "1" }}
              data-w-id="83719dd2-8efc-6aca-08a2-8183b733552e"
              alt=""
              className="image-5"
            />
            <img
              src={"./images/Ресурс-15-1.svg"}
              loading="lazy"
              style={{ opacity: "1" }}
              data-w-id="8d4eb77a-53a0-f2d6-f681-c978d013841b"
              alt=""
              className="image-5"
            />
            <img
              src={"./images/Ресурс-15-1.svg"}
              loading="lazy"
              style={{ opacity: "1" }}
              data-w-id="8b10419b-fd6f-a580-4f00-5a94198df6e3"
              alt=""
              className="image-5"
            />
            <img
              src={"./images/Ресурс-15-1.svg"}
              loading="lazy"
              style={{ opacity: "1" }}
              data-w-id="5ecfd90f-56d7-b8d5-0b82-367235b14886"
              alt=""
              className="image-5"
            />
          </div>
        </div>
      </section>
    );
  }
}

export default Title;
