import React, { useEffect } from "react";
import Audit from "./sections/Audit";
import Hero from "./sections/Hero";
import HowTo from "./sections/HowTo";
import Products from "./sections/Products";
import Multichain from "./sections/Multichain";
import Advantages from "./sections/Advantages";
import Control from "./sections/Control";
import Team from "./sections/Team";
import Monitoring from "./sections/Monitoring";
import Partners from "./sections/Partners";

function Index(props) {
  const { connectWeb3, web3Global, address } = props;

  return (
    <div>
      <Hero />
      <Products
        connectWeb3={connectWeb3}
        web3Global={web3Global}
        address={address}
      />
      <HowTo />
      <Multichain />
      <Advantages />
      <Team />
      <Control />
      <Monitoring />
      <Audit />
      <Partners />
    </div>
  );
}

export default Index;
