import React from "react";

class Audit extends React.Component {
  render() {
    return (
      <section id="audit" className="section wf-section">
        <div className="div-block-41">
          <h1 className="h2---44-52 head white _3">SECURITY AUDIT</h1>
          <div className="div-block-42">
            <div
              id="w-node-_216d5894-b874-efd5-36d6-f5273d276c24-a290b223"
              className="div-block-43"
            >
              <p className="h4-bold---24-32 white">
                Gerakon DAO is a decentralized autonomous organization based on{" "}
                <a
                  className="link-color"
                  href="https://aragon.org/"
                  target="_blank"
                >
                  Aragon
                </a>
                .
              </p>
              <p className="h4-bold---24-32 white">
                Aragon Smart Contract Audits
              </p>
              <p className="p1-regular-16-24 white">
                A number of audits have been performed on the existing smart
                contract codebase by Consensys Diligence, Authio, and others.
                Ongoing smart contract changes will continue being audited at
                the Aragon Association&#x27;s discretion.
              </p>
              <a
                href="https://githubhelp.com/ConsenSys/aragon-daotemplates-audit-report-2019-08?ysclid=l0w4ajzzbg"
                target="_blank"
                className="button main security w-inline-block"
              >
                <div className="buton-text">READ THE REPORT</div>
                <img src={"images/cil_arrow-right.svg"} loading="lazy" alt="" />
              </a>
            </div>
            <div className="div-block-43">
              <p className="h4-bold---24-32 white">Consensys Diligence</p>
              <p className="p1-regular-16-24 white _2">
                As one of the most experienced teams in the space, ConsenSys
                Diligence is at the cutting edge of offensive cryptography,
                blockchain technology, and cryptoeconomic incentive analysis.
              </p>
              <p className="h4-bold---24-32 white">Authio</p>
              <p className="p1-regular-16-24 white _2">
                Authio is a smart contract auditing and security consulting
                firm.
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Audit;
