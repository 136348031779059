import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

const initialState = {
    value: null,
    state: 'idle'
}

export const agentsSlice = createSlice({
    name: 'agents',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setAgents: (state, action) => {
            state.value = action.payload;
        }
    },
})

export const selectAgents = (state) => state.agents.value;
export const selectAgentBySlug = (state, slug) => {
    const agent = state.agents.value?.find(item => {
        return item.slug === slug;
    })
    return agent;
}

export const { setAgents } = agentsSlice.actions;

export default agentsSlice.reducer;
