import React from "react";

class Monitoring extends React.Component {
  render() {
    return (
      <section id="monitoring" className="section grey wf-section">
        <div className="div-block-38">
          <div className="div-block-31 _2">
            <h1 className="h2---44-52 head _2">MONITORING SYSTEM</h1>
            <a href="#products" className="button main w-inline-block">
              <div className="buton-text">JOIN DAO</div>
              <img src={"images/cil_arrow-right.svg"} loading="lazy" alt="" />
            </a>
          </div>
          <p className="h5---20-32 team _2">
            Gerakon DAO has developed a proprietary monitoring system that
            provides access to operational information concerning the current
            performance of the key blockchain platforms (Ethereum, Everscale,
            Solana, BSC) and the overall condition of the DeFi market.
          </p>
        </div>
        <div className="div-block-39">
          <div className="div-block-40">
            <img
              src={"images/Global-business-strategy_4.svg"}
              loading="lazy"
              alt=""
              className="image-8"
            />
            <div className="h5---20-32 p1-regular-16-24">
              Automated monitoring of capital movements on the market
            </div>
          </div>
          <div className="div-block-40">
            <img
              src={"images/Business-management-from-anywhere-in-the-world.svg"}
              loading="lazy"
              alt=""
              className="image-8"
            />
            <div className="p1-regular-16-24">
              Automated identification of potential strategies based on machine
              learning techniques
            </div>
          </div>
          <div className="div-block-40">
            <img
              src={"images/Remote-control-on-the-go_2.svg"}
              loading="lazy"
              alt=""
              className="image-8"
            />
            <div className="p1-regular-16-24">
              Monitoring the most frequent contracts (in terms of gas
              consumption) in the Ethereum network
            </div>
          </div>
          <div className="div-block-40">
            <img
              src={"images/Global-business_4.svg"}
              loading="lazy"
              alt=""
              className="image-8"
            />
            <div className="p1-regular-16-24">
              Monitoring the conditions and parameters of capital allocation in
              the TOP-30 DeFi projects
            </div>
          </div>
          <div className="div-block-40">
            <img
              src={"images/Documents.svg"}
              loading="lazy"
              alt=""
              className="image-8"
            />
            <div className="p1-regular-16-24">
              Monitoring the movement of capital and allocated positions on the
              largest smart contracts
            </div>
          </div>
          <div className="div-block-40">
            <img
              src={"images/Financial-advisor-online.svg"}
              loading="lazy"
              alt=""
              className="image-8"
            />
            <div className="p1-regular-16-24">
              Monitoring the largest cryptocurrency addresses by assets
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Monitoring;
