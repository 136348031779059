import React from "react";

class Partners extends React.Component {
  render() {
    return (
      <section id="partners" className="section grey wf-section">
        <div className="div-block-31">
          <h1 className="h2---44-52 head _2">OUR PARTNERS</h1>
          <p className="h5---20-32 team">Don't Trust — Verify</p>
        </div>
        <div
          data-w-id="87178035-b0f9-8881-b1a6-b74d6b1af0f3"
          className="div-block-55"
        >
          <div className="div-block-69">
            <img
              src="images/logo/maker.svg"
              alt=""
              loading="lazy"
              className="image-13"
            />
          </div>
          <div className="div-block-69">
            <img
              src="images/logo/compound.svg"
              alt=""
              loading="lazy"
              className="image-13"
            />
          </div>
          <div className="div-block-69">
            <img
              src="images/logo/uniswap.svg"
              alt=""
              loading="lazy"
              className="image-13"
            />
          </div>
          <div className="div-block-69">
            <img
              src="images/logo/aave.svg"
              alt=""
              loading="lazy"
              className="image-13"
            />
          </div>
          <div className="div-block-69">
            <img
              src="images/logo/curve.svg"
              alt=""
              loading="lazy"
              className="image-13"
            />
          </div>
          <div className="div-block-69">
            <img
              src="images/logo/wbtc.svg"
              alt=""
              loading="lazy"
              className="image-13"
            />
          </div>
          <div className="div-block-69">
            <img
              src="images/logo/everscale.svg"
              alt=""
              loading="lazy"
              className="image-13"
            />
          </div>
          <div className="div-block-69">
            <img
              src="images/logo/uma.svg"
              alt=""
              loading="lazy"
              className="image-13"
            />
          </div>
          <div className="div-block-69">
            <img
              src="images/logo/balancer.svg"
              alt=""
              loading="lazy"
              className="image-13"
            />
          </div>
          <div className="div-block-69">
            <img
              src="images/logo/flatqube.svg"
              alt=""
              loading="lazy"
              className="image-13"
            />
          </div>
          <div className="div-block-69">
            <img
              src="images/logo/instadapp.svg"
              alt=""
              loading="lazy"
              className="image-13"
            />
          </div>
        </div>
      </section>
    );
  }
}

export default Partners;
