import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

const initialState = {
    value: null,
    state: 'idle'
}

export const officesSlice = createSlice({
    name: 'offices',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setOffices: (state, action) => {
            state.value = action.payload;
        }
    },
})

export const selectOffices = (state) => state.offices.value;

export const { setOffices } = officesSlice.actions;

export default officesSlice.reducer;
