import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { fetchTokens } from "../widgets/daoes";
import { defaultToken, tokenInfo } from "../data/tokens";
import Product from "./product/Product";
import TokenPrice from "../widgets/TokenPrice";
import APY from "../widgets/APY";
import AddToMetaMask from "../AddToMetaMask";
import TokenRequestController from "../TokenRequestController";
import ProductRequestEmbedded from "./product/ProductRequestEmbedded";
import ProductEmpty from "./product/ProductEmpty";

function Products(props) {
  const { connectWeb3, web3Global, address } = props;
  const [tokens, setTokens] = useState([]);
  const location = useLocation();

  useEffect(() => {
    let isCancelled = false;
    const fn = async () => {
      const daoTokens = await fetchTokens();
      if (!isCancelled) {
        setTokens(daoTokens);
      }
    };
    fn();

    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <div>
      <section id="products" className="section fund-products wf-section">
        <h1 className="h2---44-52 head">FUND PRODUCTS</h1>
        {tokens.map((token) => (
          <Product
            key={token}
            tokenName={token}
            connectWeb3={connectWeb3}
            web3Global={web3Global}
            address={address}
          />
        ))}
        <ProductEmpty tokenImg={"./images/typeeverest.svg"} tvl="10 526" />
        <ProductEmpty tokenImg={"./images/typespace.svg"} tvl="10 243" />
        {/*<div data-delay="0" data-hover="false" data-w-id="24912147-7b8b-c001-de38-85b386ac799f"*/}
        {/*     style={{"height":"140px"}} className="faq-p mobi w-dropdown">*/}
        {/*  <div data-w-id="24912147-7b8b-c001-de38-85b386ac79a0" className="faq w-dropdown-toggle">*/}
        {/*    <div className="div-block-13 new">*/}
        {/*      <img src="images/typesmart.svg" loading="lazy" alt=""*/}
        {/*           className="image-14"/>*/}
        {/*      <div className="div-block-14">*/}
        {/*        <div className="div-block-15 mobile">*/}
        {/*          <div className="p2-regular-14-24 product">Total Value Locked (TVL)</div>*/}
        {/*          <h1 className="h2---44-52 prody">$4.845.649</h1>*/}
        {/*        </div>*/}
        {/*        <div className="div-block-15 mobile">*/}
        {/*          <div className="p2-regular-14-24 product">Annual Percentage Yield</div>*/}
        {/*          <h1 className="h2---44-52 annual prdddy">84%</h1>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*    <img src="images/Frame-72539.svg" loading="lazy" alt="" className="close"/>*/}
        {/*  </div>*/}
        {/*  <nav className="faq-body w-dropdown-list">*/}
        {/*    <div className="faq-body-cont">*/}
        {/*      <div className="div-block-16">*/}
        {/*        <div className="prod-div">*/}
        {/*          <div className="p2-regular-14-24 prod">Investment strategy</div>*/}
        {/*          <h1 className="h4-bold---24-32 prod">SMART</h1>*/}
        {/*          <p className="p1-regular-16-24">Imagine that you own an exchange and earn commission from each*/}
        {/*            trade</p>*/}
        {/*        </div>*/}
        {/*        <div className="prod-div">*/}
        {/*          <div className="p2-regular-14-24 prod">Details</div>*/}
        {/*          <p className="p1-regular-16-24">Stable coins are allocated across decentralized exchanges (Uniswap,*/}
        {/*            dydx, etc.) and are split in trading pairs (ETH/USDC, USDC/Dai, etc). <br/>Each exchange charges a*/}
        {/*            commission on each trade in such pairs and shares the commission with you. Flex strategy may*/}
        {/*            include other investment instruments which are introduced after DAO voting</p>*/}
        {/*        </div>*/}
        {/*        <div className="prod-div">*/}
        {/*          <div className="p2-regular-14-24 prod">Risk</div>*/}
        {/*          <ul role="list" className="list">*/}
        {/*            <li className="list-item">*/}
        {/*              <p className="p1-regular-16-24">instant withdrawal</p>*/}
        {/*            </li>*/}
        {/*            <li className="list-item">*/}
        {/*              <p className="p1-regular-16-24">limited exposure to volatile assets (e.g. ETH, if selected in*/}
        {/*                traded pair), smoothed by earned trading commissions</p>*/}
        {/*            </li>*/}
        {/*            <li className="list-item">*/}
        {/*              <p className="p1-regular-16-24">higher returns with longer investment periods</p>*/}
        {/*            </li>*/}
        {/*          </ul>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <div className="div-block-17"></div>*/}
        {/*      <div className="div-block-18">*/}
        {/*        <h1 className="h4-bold---24-32 prod _2">Token Request</h1>*/}
        {/*        <div className="w-form">*/}
        {/*          <form id="email-form" name="email-form" data-name="Email Form" method="get" className="form-2">*/}
        {/*            <div><label htmlFor="field-7" className="p2-regular-14-24 prod _2">Requested amount</label>*/}
        {/*              <div className="div-block-19"><input type="number" className="text-field-2 w-input"*/}
        {/*                                                   maxLength="256" name="0" data-name="0" placeholder="0"*/}
        {/*                                                   id="element"/><select id="field-6" name="field-6"*/}
        {/*                                                                         data-name="Field 6"*/}
        {/*                                                                         className="select-field w-select">*/}
        {/*                <option value="">SMART</option>*/}
        {/*                <option value="First">First choice</option>*/}
        {/*                <option value="Second">Second choice</option>*/}
        {/*                <option value="Third">Third choice</option>*/}
        {/*              </select></div>*/}
        {/*            </div>*/}
        {/*            <div id="w-node-_24912147-7b8b-c001-de38-85b386ac79da-a290b223"><label htmlFor="field-7"*/}
        {/*                                                                                   className="p2-regular-14-24 prod _2">Offered*/}
        {/*              amount</label>*/}
        {/*              <div className="div-block-19"><input type="number" className="text-field-2 w-input"*/}
        {/*                                                   maxLength="256" name="0" data-name="0" placeholder="0"*/}
        {/*                                                   id="element"/><select id="field-2" name="field-2"*/}
        {/*                                                                         data-name="Field 2"*/}
        {/*                                                                         className="select-field w-select">*/}
        {/*                <option value="1">USDT</option>*/}
        {/*                <option value="First">First choice</option>*/}
        {/*                <option value="Second">Second choice</option>*/}
        {/*                <option value="Third">Third choice</option>*/}
        {/*              </select></div>*/}
        {/*            </div>*/}
        {/*            <input type="submit" value="CONNECT WALLET" data-wait="Please wait..."*/}
        {/*                   id="w-node-_24912147-7b8b-c001-de38-85b386ac79e0-a290b223"*/}
        {/*                   className="submit-button w-button"/>*/}
        {/*          </form>*/}
        {/*          <div className="w-form-done">*/}
        {/*            <div>Thank you! Your submission has been received!</div>*/}
        {/*          </div>*/}
        {/*          <div className="w-form-fail">*/}
        {/*            <div>Oops! Something went wrong while submitting the form.</div>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </nav>*/}
        {/*</div>*/}
      </section>
    </div>
  );
}

export default Products;
