import React from "react";

class Multichain extends React.Component {
  render() {
    return (
      <section id="multichain" className="section grey wf-section">
        <h1 className="h2---44-52 head">MULTICHAIN SUPPORT</h1>
        <div className="div-block-26">
          <div
            data-w-id="1c0844a7-8e78-05d5-f724-c88646a43a3a"
            className="div-block-27"
          >
            <img
              src={"images/Ethereum-2.0.svg"}
              loading="lazy"
              alt=""
              className="image-6"
            />
            <h1 className="h4-bold---24-32">Ethereum</h1>
          </div>
          <div
            data-w-id="f62bfd88-ded0-d568-ae07-6b706b8ff911"
            className="div-block-27"
          >
            <img
              src={"images/Frame-99.svg"}
              loading="lazy"
              alt=""
              className="image-6"
            />
            <h1 className="h4-bold---24-32">Everscale</h1>
          </div>
          <div
            data-w-id="6f58cdef-8752-a98c-ebe1-bfe5615b0024"
            className="div-block-27"
          >
            <div className="div-block-58">
              <div className="text-block-3">SOON</div>
            </div>
            <img
              src={"images/Solana.svg"}
              loading="lazy"
              alt=""
              className="image-6"
            />
            <h1 className="h4-bold---24-32 disabled">Solana</h1>
          </div>
          <div
            data-w-id="7b78baef-8ec6-1ceb-c4d4-43a864401fd1"
            className="div-block-27"
          >
            <img
              src={"images/Layer-2.svg"}
              loading="lazy"
              alt=""
              className="image-6"
            />
            <h1 className="h4-bold---24-32 disabled">BSC</h1>
            <div className="div-block-58">
              <div className="text-block-3">SOON</div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Multichain;
