import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

const initialState = {
    value: null,
    state: 'idle'
}

export const customersSlice = createSlice({
    name: 'customers',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        set: (state, action) => {
            state.value = action.payload;
        }
    },
})

export const selectCustomers = (state) => state.customers.value;
export const selectCustomerByAddress = (state, address) => {
    const customer = state.customers.value?.find(item => {
        return item.customer.address === address;
    })
    return customer;
}

export const { set } = customersSlice.actions;

export default customersSlice.reducer;
