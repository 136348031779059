import React, { useState } from "react";

function Select(props) {
  const { value, options, setValue, valueDisplay, valueImage } = props;

  const [selectOpen, setSelectOpen] = useState(false);

  const toggleSelectOpen = () => {
    setSelectOpen(!selectOpen);
  };

  const closeAll = () => {
    setSelectOpen(false);
  };

  const setVal = (event) => {
    setValue(event.target.value);
  };

  return (
    <>
      {/*<input type="number" className="text-field-2 w-input"*/}
      {/*       maxLength="256" name="0" data-name="0" placeholder="0"*/}
      {/*       id="element"/>*/}
      <select
        id="field-2"
        name="field-2"
        data-name="Field 2"
        className="select-field w-select"
        onChange={setVal}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {valueDisplay(option)}
          </option>
        ))}
      </select>
      {/*<div className={"select" + (selectOpen ? " active" : "")}>*/}
      {/*  <div className="select__input-container" onClick={toggleSelectOpen}>*/}
      {/*    <input*/}
      {/*      className="select__input"*/}
      {/*      type="text"*/}
      {/*      value={value}*/}
      {/*      required*/}
      {/*      readOnly*/}
      {/*    />*/}
      {/*    <div className="select__value">*/}
      {/*      <img src={`/img/${valueImage(value)}.png`} title alt />*/}
      {/*      <span>{valueDisplay(value)}</span>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div className="select__options">*/}
      {/*    {options.map((option) => (*/}
      {/*      <a*/}
      {/*        key={option}*/}
      {/*        className={"select__option" + (value === option ? " _choosed" : "")}*/}
      {/*        onClick={() => {*/}
      {/*          closeAll();*/}
      {/*          setValue(option);*/}
      {/*        }}*/}
      {/*        href*/}
      {/*      >*/}
      {/*        <img src={`/img/${valueImage(option)}.png`} title alt />*/}
      {/*        <span>{valueDisplay(option)}</span>*/}
      {/*      </a>*/}
      {/*    ))}*/}
      {/*  </div>*/}
      {/*</div>*/}
    </>
  );
}

export default Select;
