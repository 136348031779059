import React from "react";
import { tokenInfo, currencies, currencyInfo } from "../../data/tokens";
import { exchanges, tokenExchanges } from "../../data/exchanges";
import Select from "../../Select";

// function Deposit(props) {
//   const { canPerformTokenRequest, performTokenRequest } = props;
//   return (
//     <td className="desktop_only">
//       <button
//         className={
//           "button _full" + (canPerformTokenRequest ? "" : " _disabled")
//         }
//         onClick={performTokenRequest}
//       >
//         Deposit
//       </button>
//     </td>
//   );
// }
//
// function Withdraw(props) {
//   const {
//     withdrawEnabled,
//     canPerformTokenWithdraw,
//     performTokenWithdraw,
//   } = props;
//   return (
//     <>
//       {withdrawEnabled ? (
//         <td className="desktop_only">
//           <button
//             className={
//               "button _full" + (canPerformTokenWithdraw ? "" : " _disabled")
//             }
//             onClick={performTokenWithdraw}
//           >
//             Withdraw
//           </button>
//         </td>
//       ) : (
//         <td />
//       )}
//     </>
//   );
// }

function TokenRequestEmbedded(props) {
  const {
    setRequestedToken,
    setOfferedToken,
    setRequestedAmount,
    setOfferedAmount,
    offeredToken,
    requestedAmount,
    offeredAmount,
    requestedToken,
    performTokenRequest,
    canPerformTokenRequest,
    performTokenWithdraw,
    canPerformTokenWithdraw,
    errorMessage,
    successMessage,
    withdrawEnabled,
    smallSum,
    address,
  } = props;

  return (
    <>
      <div className="div-block-18">
        <h1 className="h4-bold---24-32 prod _2">Token Request</h1>
        <div className="form-block-2 w-form">
          <div className="form-2">
            {address ? (
              <>
                <div className="div-block-74">
                  <label htmlFor="field-7" className="p2-regular-14-24 prod _2">
                    Requested amount
                  </label>
                  <div className="div-block-19">
                    <input
                      className="input text-field-2 w-input"
                      type="text"
                      required
                      value={requestedAmount}
                      onChange={(e) =>
                        setRequestedAmount(Number.parseFloat(e.target.value))
                      }
                    />
                    <Select
                      className="select-field w-select"
                      value={requestedToken}
                      setValue={setRequestedToken}
                      options={[requestedToken]}
                      valueDisplay={(token) => tokenInfo[token].symbol}
                      valueImage={(_) => "logo"}
                    />
                    {/*<input type="number" className="text-field-2 w-input"*/}
                    {/*                                   maxLength="256" name="0" data-name="0" placeholder="0"*/}
                    {/*                                   id="element"/>*/}
                    {/*<select id="field-6" name="field-6"*/}
                    {/*                     data-name="Field 6"*/}
                    {/*                     className="select-field w-select">*/}
                    {/*    <option value="">SMART</option>*/}
                    {/*</select>*/}
                  </div>
                </div>
                <div className="div-block-74">
                  <label htmlFor="field-7" className="p2-regular-14-24 prod _2">
                    Offered amount
                  </label>
                  <div className="div-block-19">
                    <input
                      className="text-field-2 w-input"
                      type="text"
                      required
                      value={offeredAmount}
                      onChange={(e) =>
                        setOfferedAmount(Number.parseFloat(e.target.value))
                      }
                    />
                    <Select
                      value={offeredToken}
                      className="select-field w-select"
                      setValue={setOfferedToken}
                      options={currencies}
                      valueDisplay={(currency) => currencyInfo[currency].symbol}
                      valueImage={(currency) => currencyInfo[currency].image}
                    />
                  </div>
                </div>
                <div className="div-block-74-buttons">
                  <button
                    className={
                      "submit-button w-button" +
                      (!canPerformTokenRequest ? " disabled" : "")
                    }
                    onClick={
                      canPerformTokenRequest ? performTokenRequest : null
                    }
                  >
                    DEPOSIT
                  </button>
                  {withdrawEnabled && (
                    <button
                      className={
                        "submit-button ouline-nav w-button" +
                        (!canPerformTokenWithdraw ? " disabled" : "")
                      }
                      onClick={
                        canPerformTokenWithdraw ? performTokenWithdraw : null
                      }
                    >
                      WITHDRAW
                    </button>
                  )}
                </div>
              </>
            ) : (
              <button
                className="submit-button w-button"
                onClick={performTokenRequest}
              >
                CONNECT
              </button>
            )}
          </div>
          <div className="product-h6">
            * the token request is processed within a few hours
          </div>
          <div
            className="w-form-done"
            style={{ display: successMessage ? "block" : "none" }}
          >
            <div>{successMessage}</div>
          </div>
          <div
            className="w-form-fail"
            style={{ display: errorMessage || smallSum ? "block" : "none" }}
          >
            <div>{errorMessage}</div>
          </div>
        </div>
      </div>

      {/*<tr className="desktop_only">*/}
      {/*  <td className="_right" style={{ borderBottom: "none" }}>*/}
      {/*    <div className="table-offer__label">Requested Amount</div>*/}
      {/*  </td>*/}
      {/*  <td style={{ borderBottom: "none" }}>*/}
      {/*    <div className="input-select _light">*/}
      {/*      <input*/}
      {/*        className="input"*/}
      {/*        type="text"*/}
      {/*        required*/}
      {/*        value={requestedAmount}*/}
      {/*        onChange={(e) =>*/}
      {/*          setRequestedAmount(Number.parseFloat(e.target.value))*/}
      {/*        }*/}
      {/*      />*/}
      {/*      <Select*/}
      {/*        value={requestedToken}*/}
      {/*        setValue={setRequestedToken}*/}
      {/*        options={[requestedToken]}*/}
      {/*        valueDisplay={(token) => tokenInfo[token].symbol}*/}
      {/*        valueImage={(_) => "logo"}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  </td>*/}

      {/*  <td colSpan={2} style={{ borderBottom: "none" }}>*/}
      {/*    <div className="table-offer__group">*/}
      {/*      <div className="table-offer__label">Offered Amount</div>*/}
      {/*      <div className="input-select _light flex-grow">*/}
      {/*        <input*/}
      {/*          className="input"*/}
      {/*          type="text"*/}
      {/*          required*/}
      {/*          value={offeredAmount}*/}
      {/*          onChange={(e) =>*/}
      {/*            setOfferedAmount(Number.parseFloat(e.target.value))*/}
      {/*          }*/}
      {/*        />*/}
      {/*        <Select*/}
      {/*          value={offeredToken}*/}
      {/*          setValue={setOfferedToken}*/}
      {/*          options={currencies}*/}
      {/*          valueDisplay={(currency) => currencyInfo[currency].symbol}*/}
      {/*          valueImage={(currency) => currencyInfo[currency].image}*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </td>*/}
      {/*</tr>*/}
      {/*{!smallSum && errorMessage && (*/}
      {/*  <tr className="desktop_only">*/}
      {/*    <td*/}
      {/*      colSpan={4}*/}
      {/*      className="error"*/}
      {/*      style={{ borderBottomWidth: 0, padding: 0 }}*/}
      {/*    >*/}
      {/*      <span>{errorMessage}</span>*/}
      {/*    </td>*/}
      {/*  </tr>*/}
      {/*)}*/}
      {/*{smallSum && (*/}
      {/*  <tr className="desktop_only">*/}
      {/*    <td colSpan={2} style={{ borderBottomWidth: 0 }} />*/}
      {/*    <td*/}
      {/*      colSpan={2}*/}
      {/*      className="redirect"*/}
      {/*      style={{ borderBottomWidth: 0, padding: 0, textAlign: "left" }}*/}
      {/*    >*/}
      {/*      <span className="redirect">*/}
      {/*        Dear user,{" "}*/}
      {/*        <span className="redirect_error">*/}
      {/*          Token Request works for amounts over $3,000 USDT / USDC / DAI*/}
      {/*        </span>*/}
      {/*        <br />*/}
      {/*        For smaller amounts kindly proceed to Uniswap, Sushiswap or 1Inch*/}
      {/*      </span>*/}
      {/*    </td>*/}
      {/*  </tr>*/}
      {/*)}*/}
      {/*{!smallSum && (*/}
      {/*  <tr>*/}
      {/*    <td colSpan={2} />*/}
      {/*    {withdrawEnabled ? (*/}
      {/*      <>*/}
      {/*        <Deposit*/}
      {/*          canPerformTokenRequest={canPerformTokenRequest}*/}
      {/*          performTokenRequest={performTokenRequest}*/}
      {/*        />*/}
      {/*        <Withdraw*/}
      {/*          withdrawEnabled={withdrawEnabled}*/}
      {/*          canPerformTokenWithdraw={canPerformTokenWithdraw}*/}
      {/*          performTokenWithdraw={performTokenWithdraw}*/}
      {/*        />*/}
      {/*      </>*/}
      {/*    ) : (*/}
      {/*      <>*/}
      {/*        <td />*/}
      {/*        <Deposit*/}
      {/*          canPerformTokenRequest={canPerformTokenRequest}*/}
      {/*          performTokenRequest={performTokenRequest}*/}
      {/*        />{" "}*/}
      {/*      </>*/}
      {/*    )}*/}
      {/*  </tr>*/}
      {/*)}*/}
      {/*{smallSum && (*/}
      {/*  <tr>*/}
      {/*    <td colSpan={2} />*/}
      {/*    <td colSpan={2}>*/}
      {/*      <div className="buttons_container">*/}
      {/*        {Object.keys(exchanges).map((exchange) => (*/}
      {/*          <a*/}
      {/*            target="_blank"*/}
      {/*            key={exchange}*/}
      {/*            className={*/}
      {/*              "button _medium" +*/}
      {/*              (canPerformTokenRequest ? "" : " _disabled")*/}
      {/*            }*/}
      {/*            href={tokenExchanges[requestedToken][exchange] || "#"}*/}
      {/*            onClick={performTokenRequest}*/}
      {/*          >*/}
      {/*            Buy on {exchanges[exchange]}*/}
      {/*          </a>*/}
      {/*        ))}*/}
      {/*      </div>*/}
      {/*    </td>*/}
      {/*  </tr>*/}
      {/*)}*/}
    </>
  );
}

export default TokenRequestEmbedded;
