import React from "react";
import { Link } from "react-router-dom";
import { defaultToken } from "./data/tokens";
import useCustomAuth from "./admin/auth/AuthProvider";

function Footer(props) {
  const {userRole} = useCustomAuth();
  const { isBlocked, isCollapsed } = props;
  return (
    <>
      {!isCollapsed ? (
        <footer id="contacts" className="footer open">
          <div className="div-block-72">
            <div className="div-block-5">
              <img
                src="/images/Ресурс-21-12.svg"
                loading="lazy"
                alt=""
                className="logo-foot"
              />
              <div className="div-block-3">
                <Link
                  to="/dashboard"
                  className="button outlined foot w-inline-block"
                >
                  <div className="buton-text text">DASHBOARD</div>
                </Link>
                {!isBlocked && (
                  <Link to="/#products" className="button main w-inline-block">
                    <div className="buton-text">JOIN DAO</div>
                    <img
                      src="/images/cil_arrow-right.svg"
                      loading="lazy"
                      alt=""
                    />
                  </Link>
                )}
              </div>
              <div className="foot-menu">
                <Link to="/privacy" className="foot-link w-inline-block">
                  <div>PRIVACY POLICY</div>
                </Link>
                <Link to="/cookie" className="foot-link w-inline-block">
                  <div>COOKIE POLICY</div>
                </Link>
                <Link to="/contacts" className="foot-link w-inline-block">
                  <div>CONTACTS</div>
                </Link>
              </div>
            </div>
            <div className="div-block-6">
              <div className="footer-form">
                <h1 className="h3---32-40 form">
                  Let’s build Defi community together!
                </h1>
                <div className="form-block w-form">
                  <form
                    id="email-form"
                    name="email-form"
                    data-name="Email Form"
                    method="get"
                    className="form"
                  >
                    <input
                      type="email"
                      className="text-field w-input"
                      maxLength="256"
                      name="Email-Address-2"
                      data-name="Email Address 2"
                      placeholder="Email Address"
                      id="Email-Address-2"
                      required=""
                    />
                    <input
                      type="submit"
                      value="SUBSCRIBE"
                      data-wait="Please wait..."
                      className="button form w-button"
                    />
                  </form>
                  <div className="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                  <div className="w-form-fail">
                    <div>
                      Oops! Something went wrong while submitting the form.
                    </div>
                  </div>
                </div>
                <div className="p1-regular-16-24 form">
                  By subscribing you agree to receive marketing emails from
                  Gerakon DAO.
                </div>
              </div>
            </div>
          </div>
        </footer>
      ) : (
        <footer className="footer collapsed">
          <div className="div-block-71">
            <div className="div-block-8">
              <div className="div-block-7">
                <img
                  src="/images/Ресурс-21-12.svg"
                  loading="lazy"
                  alt=""
                  className="logo-foot small"
                />
                <div className="foot-menu small">
                  <Link to="/privacy" className="foot-link smal w-inline-block">
                    <div>PRIVACY POLICY</div>
                  </Link>
                  <Link to="/cookie" className="foot-link smal w-inline-block">
                    <div>COOKIE POLICY</div>
                  </Link>
                  <Link
                    to="/contacts"
                    className="foot-link smal w-inline-block"
                  >
                    <div>CONTACTS</div>
                  </Link>
                  {!userRole && (
                      <Link
                          to="/login"
                          className="foot-link smal w-inline-block"
                      >
                        <div>AGENTS</div>
                      </Link>
                  )}
                  {userRole === 'manager' && (
                      <Link
                          to="/managers"
                          className="foot-link smal w-inline-block"
                      >
                        <div>MANAGER</div>
                      </Link>
                  )}
                  {userRole === 'agent' && (
                      <Link
                          to="/agents"
                          className="foot-link smal w-inline-block"
                      >
                        <div>AGENT</div>
                      </Link>
                  )}
                  {userRole === 'admin' && (
                      <Link
                          to="/admin"
                          className="foot-link smal w-inline-block"
                      >
                        <div>ADMIN</div>
                      </Link>
                  )}
                </div>
              </div>
              <div className="p2-regular-14-24 foot-small">
                © 2022 Gerakon LLC, 1201 N. Orange Street Suite 7160,
                Wilmington, 19801 Delaware. All rights reserved.
              </div>
            </div>
            <div className="div-block-3">
              <Link
                to="/dashboard"
                className="button outlined foot w-inline-block"
              >
                <div className="buton-text text">DASHBOARD</div>
              </Link>
              <Link to="/#products" className="button main w-inline-block">
                <div className="buton-text">JOIN DAO</div>
                <img src="/images/cil_arrow-right.svg" loading="lazy" alt="" />
              </Link>
            </div>
          </div>
        </footer>
      )}
    </>
  );
}

export default Footer;
