import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import useCustomAuth from "./admin/auth/AuthProvider";

function MenuItems(props) {
  const { connectWeb3, address, location, isMobileVisible } = props;
  const {userRole} = useCustomAuth()

  return (
    <>
      <div className="div-block-57">
        {location.pathname === "/" ? (
          <div>
            {userRole === 'admin' && (
              <Link
                to="/admin"
                className={
                  "nav-link w-nav-link " +
                  (isMobileVisible ? "w--nav-link-open" : "")
                }
              >
                ADMIN
              </Link>
            )}
            {userRole === 'manager' && (
                <Link
                    to="/managers"
                    className={
                      "nav-link w-nav-link " +
                      (isMobileVisible ? "w--nav-link-open" : "")
                    }
                >
                  MANAGER
                </Link>
            )}
            {userRole === 'agent' && (
                <Link
                    to="/agents"
                    className={
                      "nav-link w-nav-link " +
                      (isMobileVisible ? "w--nav-link-open" : "")
                    }
                >
                  AGENT
                </Link>
            )}
            <Link
              to="/about"
              className={
                "nav-link w-nav-link " +
                (isMobileVisible ? "w--nav-link-open" : "")
              }
            >
              ABOUT US
            </Link>
            <Link
              to="/#products"
              className={
                "nav-link w-nav-link " +
                (isMobileVisible ? "w--nav-link-open" : "")
              }
            >
              PRODUCTS
            </Link>
            <Link
              to="/#advantages"
              className={
                "nav-link w-nav-link " +
                (isMobileVisible ? "w--nav-link-open" : "")
              }
            >
              WHY WE
            </Link>
            <Link
              to="/#monitoring"
              className={
                "nav-link w-nav-link " +
                (isMobileVisible ? "w--nav-link-open" : "")
              }
            >
              MONITORING
            </Link>
            <Link
              to="/#audit"
              className={
                "nav-link w-nav-link " +
                (isMobileVisible ? "w--nav-link-open" : "")
              }
            >
              AUDIT
            </Link>
            <Link
              to="/#partners"
              className={
                "nav-link w-nav-link " +
                (isMobileVisible ? "w--nav-link-open" : "")
              }
            >
              PARTNERS
            </Link>
          </div>
        ) : (
          <div>
            {userRole === 'admin' && (
              <Link
                to="/admin"
                className={
                  "nav-link w-nav-link " +
                  (isMobileVisible ? "w--nav-link-open" : "")
                }
              >
                ADMIN
              </Link>
            )}
            {userRole === 'agent' && (
                <Link
                    to="/agents"
                    className={
                      "nav-link w-nav-link " +
                      (isMobileVisible ? "w--nav-link-open" : "")
                    }
                >
                  AGENT
                </Link>
            )}
            {userRole === 'manager' && (
                <Link
                    to="/managers"
                    className={
                      "nav-link w-nav-link " +
                      (isMobileVisible ? "w--nav-link-open" : "")
                    }
                >
                  MANAGER
                </Link>
            )}
            <Link to="/about" className="nav-link w-nav-link">
              ABOUT US
            </Link>
            <Link to="/" className="nav-link w-nav-link">
              PRODUCTS
            </Link>
          </div>
        )}
      </div>
      <div className="div-block">
        {address ? (
          <div>
            <Link className="ouline-nav w-button" to="/account">
              {address.substr(0, 5) +
                ".." +
                address.substr(address.length - 5, 5)}
            </Link>
            <Link to="/dashboard" className="main-nav w-button">
              DASHBOARD
            </Link>
          </div>
        ) : (
          <div>
            <a className="ouline-nav w-button" onClick={connectWeb3} href="#">
              CONNECT WALLET
            </a>
            <Link className="main-nav w-button" to="/#products">
              JOIN DAO
            </Link>
          </div>
        )}
      </div>
    </>
  );
}

function Header(props) {
  const { connectWeb3, address } = props;
  const location = useLocation();
  const [isMobileVisible, setIsMobileVisible] = useState(false);

  useEffect(() => {
    setIsMobileVisible(false);
  }, [location]);

  const toggleVisibility = () => {
    isMobileVisible ? setIsMobileVisible(false) : setIsMobileVisible(true);
  };

  return (
    <div
      data-w-id="aef94c98-b87b-5b08-f9c0-c2d19da44aeb"
      data-animation="default"
      data-collapse="medium"
      data-duration="400"
      data-easing="ease"
      data-easing2="ease"
      role="banner"
      className="navbar w-nav"
    >
      <div className="div-block-56">
        <Link
          to={location.pathname === "/" ? "/#hero" : "/"}
          aria-current="page"
          className="brand w-nav-brand w--current"
        >
          <img
            src={"/images/Ресурс-21-1.svg"}
            loading="lazy"
            alt=""
            className="image"
          />
        </Link>
        <div className="div-block-2">
          <nav role="navigation" className="nav-menu w-nav-menu">
            <MenuItems
              connectWeb3={connectWeb3}
              address={address}
              location={location}
              isMobileVisible={isMobileVisible}
            />
          </nav>
          <div
            className={
              "menu-button w-nav-button " + (isMobileVisible ? "w--open" : "")
            }
            onClick={toggleVisibility}
          >
            <div className="w-icon-nav-menu"></div>
          </div>
        </div>
      </div>

      <div
        className="w-nav-overlay"
        id="w-nav-overlay-0"
        style={{ height: "100vh", display: isMobileVisible ? "block" : "none" }}
      >
        <nav
          role="navigation"
          className="nav-menu w-nav-menu"
          style={{
            transform: "translateY(0px) translateX(0px)",
            transition: "transform 400ms ease 0s",
          }}
          data-nav-menu-open=""
        >
          <MenuItems
            connectWeb3={connectWeb3}
            address={address}
            location={location}
            isMobileVisible={isMobileVisible}
          />
        </nav>
      </div>
    </div>
  );
}

export default Header;
