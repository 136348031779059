import React from "react";
import { Link } from "react-router-dom";
import TVLBalance from "../TVLBalance";
import FundCap from "../widgets/FundCap";

class Hero extends React.Component {
  render() {
    return (
      <div>
        <section id="hero" className="section hero wf-section">
          <div className="div-block-9">
            <img
              src={"/images/Frame-23.svg"}
              loading="lazy"
              style={{ opacity: "1" }}
              data-w-id="60904435-5f28-a63a-35c5-7dffc6f4f13a"
              alt=""
              className="image-2"
            />
            <p
              data-w-id="681a048a-1a3d-6121-d8da-6dfc697444fc"
              style={{ opacity: "1" }}
              className="h4-regular---24-32 hero"
            >
              We are an investment fund
              <br />
              focused on blockchain technologies
            </p>
            <div
              data-w-id="6fa771da-223c-53cc-23f9-3ea5686c4588"
              style={{ opacity: "1" }}
              className="div-block-10"
            >
              <Link
                to="/dashboard"
                className="button outlined hero w-inline-block"
              >
                <div className="buton-text text">DASHBOARD</div>
              </Link>
              <a href="#products" className="button main w-inline-block">
                <div className="buton-text">JOIN DAO</div>
                <img src={"images/cil_arrow-right.svg"} loading="lazy" alt="" />
              </a>
            </div>
          </div>
          <div
            data-w-id="a127a71f-162e-1435-a574-5956ada0ea8e"
            style={{ opacity: "1" }}
            className="div-block-11"
          >
            {/*<div*/}
            {/*  id="w-node-ce67bc00-3166-4117-b502-49a1a18ed041-a290b223"*/}
            {/*  className="stats _1"*/}
            {/*>*/}
            {/*  <img*/}
            {/*    src="/images/Frame-7291-1.svg"*/}
            {/*    loading="lazy"*/}
            {/*    alt=""*/}
            {/*    className="image-3"*/}
            {/*  />*/}
            {/*  <div>*/}
            {/*    <h1 className="counter">*/}
            {/*      <FundCap />*/}
            {/*    </h1>*/}
            {/*    <div className="p1-regular-16-24 hero">Fund Assets</div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="stats">
              <img
                src="/images/icon-tvl.svg"
                loading="lazy"
                alt=""
                className="image-3"
              />
              <TVLBalance />
            </div>
          </div>
        </section>
        <section id="empty" className="div-block-59"></section>
      </div>
    );
  }
}

export default Hero;
