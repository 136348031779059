import React from "react";

class Title extends React.Component {
  render() {
    return (
      <section id="control" className="section wf-section">
        <div className="div-block-31">
          <h1 className="h2---44-52 head white _2">
            CONTROL OVER FUNDS BY INVESTORS
          </h1>
          <p className="h5---20-32 team _2">
            Gerakon DAO is one of the few decentralized finance hedge funds that
            provides full control over the invested funds to the investors with
            capital contributions of $1M+
          </p>
        </div>
        <div className="div-block-36">
          <div className="div-block-34">
            <div className="div-block-35">
              <div className="h2---44-52 yellow">01</div>
              <h1 className="h2---44-52 white">Own control</h1>
            </div>
            <p className="h5---20-32 white">
              An investor receives the keys to a multi-signature smart contract
              and any changes to the smart contract are impossible without the
              consent of the investor, there is also an option to rescue funds
              in case of emergency solely by the investor.
              <br />
            </p>
          </div>
          <div className="div-block-37"></div>
        </div>
        <div className="div-block-36">
          <div className="div-block-34">
            <div className="div-block-35">
              <div className="h2---44-52 yellow">02</div>
              <h1 className="h2---44-52 white">Non-risk</h1>
            </div>
            <p className="h5---20-32 white">
              Gerakon DAO uses its own capital to test and develop new DeFi
              structured products and investment strategies. Investors’ funds
              are not used for testing new strategies.
            </p>
          </div>
          <div className="div-block-37"></div>
        </div>
        <div className="div-block-36">
          <div className="div-block-34">
            <div className="div-block-35">
              <div className="h2---44-52 yellow">03</div>
              <h1 className="h2---44-52 white">Best solutions</h1>
            </div>
            <p className="h5---20-32 white">
              Gerakon DAO applies best-in-class solutions to ensure distributed
              access control over the funds.
            </p>
          </div>
          <div className="div-block-37"></div>
        </div>
      </section>
    );
  }
}

export default Title;
