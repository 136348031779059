import React from "react";

class Title extends React.Component {
  render() {
    return (
      <section id="team" className="section grey wf-section">
        <div className="div-block-31">
          <h1 className="h2---44-52 head _2">MEET THE TEAM</h1>
          <p className="h5---20-32 team">
            Gerakon DAO is a team of investment management professionals
            experienced developers and qualified traders
          </p>
        </div>
        <div className="div-block-32">
          <div
            data-w-id="9efcd5fc-af7e-6ec4-fb9c-f676785bcf7f"
            className="div-block-33"
          >
            <h1 className="h1---52-64 yellow">10+</h1>
            <div className="h5---20-32">years of IT development expertise</div>
          </div>
          <div
            data-w-id="a8318915-a8e4-4875-50b8-f008b3db5ff4"
            className="div-block-33"
          >
            <h1 className="h1---52-64 yellow">10+</h1>
            <div className="h5---20-32">years of investment experience</div>
          </div>
          <div
            data-w-id="2ec94d5b-a8cd-0faa-9ca8-4daf2cf50f50"
            className="div-block-33"
          >
            <h1 className="h1---52-64 yellow">10+</h1>
            <div className="h5---20-32">years of trading experience</div>
          </div>
        </div>
      </section>
    );
  }
}

export default Title;
