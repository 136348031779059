import Application from "./components/Application";
import { MoralisProvider } from "react-moralis";
import React from "react";
import ReferralHandler from "./components/ReferralHandler";
import {AuthProvider} from "./components/admin/auth/AuthProvider";

const APP_ID = process.env.REACT_APP_MORALIS_APPLICATION_ID;
const SERVER_URL = process.env.REACT_APP_MORALIS_SERVER_URL;

function App() {
  return (
    <MoralisProvider appId={APP_ID} serverUrl={SERVER_URL}>
        <AuthProvider>
            <Application />
        </AuthProvider>
    </MoralisProvider>
  );
}

export default App;
