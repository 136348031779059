import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import SidebarActivation from "./SidebarActivation";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import HeaderBlocked from "./HeaderBlocked";
import BlockedPolicy from "./BlockedPolicy";

function Main() {
  return (
    <Router>
      <main className="main">
        <section id="content" className="section _2 policy wf-section">
          <div className="div-block-47 empty">
            <h1 className="h3---32-40 empty">
              Sorry, but gerakon dao is not available in your country
            </h1>
            <p className="h5---20-32 acc empty">
              Be the first to know when we launch:
              <br />
              <a className="link-color" href="mailto:support@gerakon.com">
                support@gerakon.com
              </a>
            </p>
          </div>
        </section>
      </main>
    </Router>
  );
}

export default Main;
