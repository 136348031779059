export const validTokenIds = [
  "cb603cfd-8d5c-0fa2-ea82-b2b77d5af53a",
  // "b2429e2b-9425-550d-22b6-48d030f4184b",
];
export const defaultToken = [];
export const tokenInfo = {
  /*
  flex: {
    name: "Flex",
    fullName: "Berezka Flex",
    address: "0x0D7DeA5922535087078dd3D7c554EA9f2655d4cB",
    tableName: "Flex",
    symbol: "FLEX",
    dao: "0xac3f8e8518139f732218ff542d21cd6968e8209d",
    maximumDiff: 10,
    isDexEnabled: true,
  },*/
};

export const tokens = [
  /*
  "flex",
  */
];

export const tokenAddresses = [];

export const tokenAddInfo = {
  "cb603cfd-8d5c-0fa2-ea82-b2b77d5af53a": {
    name: "SMART",
    image: "images/typesmart.svg",
    display_short_description:
      "Imagine that you own an exchange and earn commission from each trade",
    details: [
      "Stable coins are allocated across decentralized exchanges (Uniswap, AAVE, Curve, etc.) and are split in trading pairs (ETH/USDC, WEVER/USDT, DAI/USDC, etc).",
      "Each exchange charges a commission on each trade in such pairs and shares the commission with you. Flex strategy may include other investment instruments which are introduced after DAO voting.",
    ],
    risk: [
      "Instant withdrawal.",
      "Limited exposure to volatile assets (e.g. ETH, if selected in traded pair), smoothed by earned trading commissions.",
      "We are using these auditing protocols.",
    ],
    wallets: [
      "https://tonscan.io/accounts/0:e16e3bb5a43dfffa9dc1001ead98cd30adae2e229772c308f5f54d9a8b80cd78",
      "https://app.zerion.io/0x92c5b6d086ad41f459e708800f3310a1ef9a8a71/overview",
    ],
  },
  "b2429e2b-9425-550d-22b6-48d030f4184b": {
    name: "TEST DAO",
    image: "images/typesmart.svg",
    display_short_description: "TEST DAO description",
    details: [
      "Stable coins are allocated across decentralized exchanges (Uniswap, dydx, etc.) and are split in trading pairs (ETH/USDC, USDC/Dai, etc).",
      "Each exchange charges a commission on each trade in such pairs and shares the commission with you. Flex strategy may include other investment instruments which are introduced after DAO voting",
    ],
    risk: [
      "instant withdrawal",
      "limited exposure to volatile assets (e.g. ETH, if selected in traded pair), smoothed by earned trading commissions",
      "higher returns with longer investment periods",
    ],
  },
};

export const currencyInfo = {
  usdt: {
    symbol: "USDT",
    address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    decimals: 6,
    image: "type4",
  },
  usdc: {
    symbol: "USDC",
    address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    decimals: 6,
    image: "type5",
  },
  dai: {
    symbol: "DAI",
    address: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
    decimals: 18,
    image: "type3",
  },
  tusdt: {
    symbol: "TUSDT",
    address: "0xD92E713d051C37EbB2561803a3b5FBAbc4962431",
    decimals: 6,
    image: "type3",
  },
};

export const currencies = ["usdt", "usdc", "dai"];

export const nameByAddress = (address) =>
  [...tokens]
    .map((token) => ({
      ...tokenInfo[token],
      name: token,
    }))
    .find((info) => info.address.toLowerCase() === address.toLowerCase()).name;
