import {useLocation, Redirect} from "react-router-dom";
import useCustomAuth from "./AuthProvider";

const RequireAuth = ({ allowedRoles, children }) => {
    const { customAuth } = useCustomAuth();
    const location = useLocation();

    return (
        customAuth?.roles?.find(role => allowedRoles?.includes(role))
            ? children
            : customAuth?.user
            ? <Redirect to="/404" state={{ from: location }} />
            : <Redirect to="/login" state={{ from: location }} />
    );
}

export default RequireAuth;