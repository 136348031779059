import {createContext, useContext, useState} from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [customAuth, setCustomAuth] = useState({});

    const customLogin = (data) => {
        setCustomAuth((customAuth) => ({
            user: data.user, roles: data.roles, office: data.office
        }));
    };

    const customLogout = () => {
        setCustomAuth((customAuth) => ({}));
    }


    const userRole = customAuth?.roles?.find(role => role === 'manager') ? 'manager' : customAuth?.roles?.at(0)

    return (
        <AuthContext.Provider value={{customAuth, customLogin, customLogout, userRole}}>
            {children}
        </AuthContext.Provider>
    )
}

const useCustomAuth = () => useContext(AuthContext);

export default useCustomAuth;