import React from "react";

class Title extends React.Component {
  render() {
    return (
      <section id="advantages" className="section wf-section">
        <h1 className="h2---44-52 head white">WHY WE</h1>
        <div className="div-block-28">
          <div
            data-w-id="3224d399-011c-0834-84dc-bdb578bc7b31"
            className="div-block-29"
          >
            <img
              src={"images/Frame-1.svg"}
              loading="lazy"
              alt=""
              className="image-7"
            />
            <h1 className="h4-bold---24-32 white">Audit</h1>
            <p className="p1-regular-16-24 white">
              Continuous security audit of program codes and infrastructure
            </p>
          </div>
          <div
            data-w-id="e089a4e5-b943-2585-651e-f0db0e06c782"
            className="div-block-29"
          >
            <img
              src={"images/Frame-1-1.svg"}
              loading="lazy"
              alt=""
              className="image-7"
            />
            <h1 className="h4-bold---24-32 white">Strategies</h1>
            <p className="p1-regular-16-24 white">
              The best expertise in the development and automation of DeFi
              trading strategies on the blockchain
            </p>
          </div>
          <div
            data-w-id="13b84a03-085a-1285-f130-0b86bc3b9bc6"
            className="div-block-29"
          >
            <img
              src={"images/Frame-1-2.svg"}
              loading="lazy"
              alt=""
              className="image-7"
            />
            <h1 className="h4-bold---24-32 white">Management</h1>
            <p className="p1-regular-16-24 white">
              Extensive experience in the field of capital management in
              traditional financial and cryptocurrency markets provides for high
              yield and record-breaking risk minimization
            </p>
          </div>
          <div
            data-w-id="cae0aaf2-4b56-71cc-dfb1-cd81429cfcb7"
            className="div-block-29"
          >
            <img
              src={"images/Frame-1-3.svg"}
              loading="lazy"
              alt=""
              className="image-7"
            />
            <h1 className="h4-bold---24-32 white">User experience</h1>
            <p className="p1-regular-16-24 white">
              Gerakon DAO platform offers a combination of seamless user
              experience, military-grade security with 256-bit encryption and
              cutting-edge technological advances, as well as 24/7/365 Fraud
              Monitoring
            </p>
          </div>
          <div
            data-w-id="a657bfe6-b433-c0dc-046e-c1ec46249beb"
            className="div-block-29"
          >
            <img
              src={"images/Frame-1-4.svg"}
              loading="lazy"
              alt=""
              className="image-7"
            />
            <h1 className="h4-bold---24-32 white">Money control</h1>
            <p className="p1-regular-16-24 white">
              Gerakon DAO offers you full flexibility by allowing you to add and
              withdraw funds at any time
            </p>
          </div>
          <div
            data-w-id="f2d3aade-a99e-c181-1dae-79f3815d9b1f"
            className="div-block-30"
          >
            <h1 className="h2---44-52 join">
              Let’s build Defi community together!
            </h1>
            <a href="#products" className="button main join w-inline-block">
              <div className="buton-text">JOIN DAO</div>
              <img src={"images/cil_arrow-right.svg"} loading="lazy" alt="" />
            </a>
          </div>
        </div>
      </section>
    );
  }
}

export default Title;
