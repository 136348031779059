import React from "react";
import TotalPrice from "./widgets/TotalPrice";
import { tokenAddresses } from "./data/tokens";

function TVLBalance(props) {
  const { value } = props;
  return (
    <div>
      <div className="div-block-25">
        <h1 className="counter">
          {value || <TotalPrice tokens={tokenAddresses} />}
        </h1>
      </div>
      <div className="p1-regular-16-24 hero">Total Value Locked (TVL)</div>
    </div>
  );
}

export default TVLBalance;
