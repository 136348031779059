import React, { useState, useEffect, useCallback } from "react";
import Main from "./Main";
import Web3 from "web3";
import Web3Modal from "web3modal";
import CountryBlock from "./CountryBlock";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { fillTokens } from "./widgets/daoes";
import { useMoralis } from "react-moralis";
import { useLocalStorage } from "./widgets/useLocalStorage";
import ReferralHandler from "./ReferralHandler";
import { BrowserRouter as Router } from "react-router-dom";
import useCustomAuth, { AuthProvider } from "./admin/auth/AuthProvider";
import { setAgents } from "./admin/reducers/agentsSlice";

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: "253ee0e1efea46419ff33ef9f4ce2998",
    },
  },
};

const web3Modal = new Web3Modal({
  network: "mainnet",
  cacheProvider: true,
  theme: "dark",
  providerOptions,
});

const Application = () => {
  const [web3Local, setWeb3Local] = useState(null);
  const [web3Global, setweb3Global] = useState(null);
  const [address, setAddress] = useState(null);
  const [countryCode, setCountryCode] = useState(null);
  const [tokenFilled, setTokenFilled] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const { customAuth, customLogin } = useCustomAuth();
  const {
    enableWeb3,
    Moralis,
    authenticate,
    isAuthenticated,
    isInitialized,
  } = useMoralis();
  const [au, setAu] = useLocalStorage("auth", { roles: [], office: null });

  const setReferer = async () => {
    // DB Referer check
    const dbRef = new Moralis.Query("AgentCustomer");
    const user = Moralis.User.current();
    dbRef.equalTo("customer", user);
    const relRef = await dbRef.first();
    const referer = JSON.parse(window.localStorage.getItem("REFERER"));

    if (relRef) {
      console.log("Already a member");
    } else {
      const AgentCustomer = Moralis.Object.extend("AgentCustomer");
      const ACL = new Moralis.ACL();
      ACL.setRoleWriteAccess("admin", true);
      ACL.setRoleReadAccess("admin", true);
      ACL.setPublicReadAccess(true);
      ACL.setPublicWriteAccess(false);
      // console.log(referer);
      if (referer) {
        // console.log("I have in Localstorage, lets save in DB");
        const dbLink = new Moralis.Query("Link");
        dbLink.equalTo("slug", referer);
        const link = await dbLink.first();
        if (link) {
          console.log("Attaching to the referer agent");
          const newRelAC = new AgentCustomer();
          const dbAgent = new Moralis.Query(Moralis.User);
          const { email } = link.attributes;
          dbAgent.equalTo("username", email);
          const agent = await dbAgent.first();
          ACL.setRoleReadAccess(agent.id, true);
          newRelAC.set("agent", agent);
          newRelAC.set("customer", user);
          newRelAC.setACL(ACL);
          await newRelAC.save();
          return true;
        } else {
          console.log("No such Referer");
          console.log("Attached to the default one");
          const newRelAC = new AgentCustomer();
          newRelAC.setACL(ACL);
          const defaultAgent = Moralis.User.createWithoutData(
            "ZmRG5hW4gALKRmDvsO6KxYfb"
          );
          ACL.setRoleReadAccess(defaultAgent.id, true);
          newRelAC.set("agent", defaultAgent);
          newRelAC.set("customer", user);
          await newRelAC.save();
        }
      } else {
        console.log("Attached to the default one");
        const newRelAC = new AgentCustomer();
        newRelAC.setACL(ACL);
        const defaultAgent = Moralis.User.createWithoutData(
          "ZmRG5hW4gALKRmDvsO6KxYfb"
        );
        newRelAC.set("agent", defaultAgent);
        newRelAC.set("customer", user);
        await newRelAC.save();
      }
    }
  };

  const saveTokenRequests = async (amount, currency) => {
    const params = { amount: amount, currency: currency };
    const createdPayment = await Moralis.Cloud.run(
      "newCustomerPayment",
      params
    );
    if (createdPayment) {
      console.log("New payment saved");
    }
  };

  const handleAuth = async (provider) => {
    try {
      // Enable web3 to get user address and chain
      await enableWeb3({ throwOnError: true, provider });
      const { account, chainId } = Moralis;

      if (!account) {
        throw new Error(
          "Connecting to chain failed, as no connected account was found"
        );
      }
      if (!chainId) {
        throw new Error(
          "Connecting to chain failed, as no connected chain was found"
        );
      }

      // Get message to sign from the auth api
      const { message } = await Moralis.Cloud.run("requestMessage", {
        address: account,
        chain: parseInt(chainId, 16),
        network: "evm",
      });

      // Authenticate and login via parse
      await authenticate({
        signingMessage: message,
        throwOnError: true,
      });
    } catch (error) {
      //
    } finally {
      //
    }
  };

  const connectWeb3 = async () => {
    // await handleAuth();
    try {
      const provider = await web3Modal.connect();
      const web3 = new Web3(provider);
      const address = await web3.currentProvider.enable();
      setWeb3Local(web3);
      setAddress(address[0]);
      if (
        !isAuthenticated ||
        address[0] !== Moralis.User.current().get("ethAddress")
      ) {
        await handleAuth(provider);
        setReferer();
        // authenticate({
        //   signingMessage: "Connect Wallet to the Gerakon DAO",
        //   provider: !provider.isWalletConnect ? provider : "walletConnect",
        // }).then((res) => {
        //   if(au.user){
        //     setAu({roles: [], office: null})
        //   }
        //   if(res){
        //     setReferer();
        //   }else{
        //     authenticate({
        //       signingMessage: "Connect Wallet to the Gerakon DAO",
        //       provider: !provider.isWalletConnect ? provider : "walletConnect",
        //     }).then((res) => {
        //       if(res) {
        //         setReferer();
        //       }
        //     })
        //   }
        // }).catch(e => {
        //   console.log(e)
        // });
      }
      return [web3, address[0], saveTokenRequests];
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    const web3 = new Web3(
      new Web3.providers.HttpProvider(
        "https://eth-mainnet.alchemyapi.io/v2/-r5u2mpuXHKVbfRG5fRAEMmMS9Ix4eHF"
      )
    );
    setweb3Global(web3);
  }, []);

  // const { fetch, isLoading } = useMoralisCloudFunction("fundAssets", {}, {
  //   autoFetch: false
  // });

  useEffect(() => {
    const fn = async () => {
      if (!countryCode) {
        const geo = await fetch("https://api.country.is/")
          .then((res) => res.json())
          .catch((err) => {
            return { country: "RU" };
          });
        setCountryCode(geo.country);
      }
    };
    fn();
  }, [countryCode]);

  const setRoles = async () => {
    if (isInitialized && isAuthenticated) {
      const user = Moralis.User.current();
      if (au?.roles?.length === 0) {
        const userRoleQuery = new Moralis.Query(Moralis.Role);
        userRoleQuery.equalTo("users", user);
        userRoleQuery.find().then((res) => {
          let rs = [];
          res.forEach((role, index) => {
            rs.push(role?.get("name"));
          });
          let office;
          if (rs.includes("manager") || rs.includes("agent")) {
            const officeQuery = new Moralis.Query("OfficeAgent");
            officeQuery.include("office");
            officeQuery.equalTo("agent", user);
            officeQuery.first().then((o) => {
              const o2 = o?.get("office");
              office = o2.get("code");
              setAu({ roles: rs, office: office });
              customLogin({ user: user, roles: rs, office: office });
            });
          } else {
            setAu({ roles: rs, office: null });
            customLogin({ user: user, roles: rs, office: office });
          }
        });
      } else {
        customLogin({ user: user, roles: au.roles, office: au.office });
      }
      // console.log(customAuth)
    }
  };

  useEffect(() => {
    const fn = async () => {
      await setRoles();
    };
    fn();
  }, [isAuthenticated]);

  useEffect(() => {
    const fn = async () => {
      await fillTokens();
      setTokenFilled(true);
    };
    fn();
  });

  if (!tokenFilled) {
    return <></>;
  }

  return (
    <>
      <ReferralHandler />
      <CountryBlock countryCode={countryCode}>
        <Main
          address={address}
          web3Local={web3Local}
          web3Global={web3Global}
          connectWeb3={connectWeb3}
          countryCode={countryCode}
          isAdmin={isAdmin}
        />
      </CountryBlock>
    </>
  );
};

export default Application;
