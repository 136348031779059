import React, { useEffect, useState } from "react";
import { useTokenData } from "./useTokenData";
import { fetchDao, fetchTokens } from "./daoes";

const APY_OLD = (props) => {
  const { tokenAddress, decimals, isLegacy } = props;
  const { loading, merged } = useTokenData(tokenAddress, isLegacy);

  if (loading) {
    return <>...</>;
  }

  const actualDecimals = decimals === undefined ? 2 : 0;
  const last = merged[0];
  const first = merged[merged.length - 1];
  const lastPrice = last.priceAfterCarry || last.price;
  const firstPrice = first.priceAfterCarry || first.price;
  const daysBetween = last.dayId - first.dayId;

  // ((Pn/P0)-1)*100% /Nдней*365
  const amount = (((lastPrice / firstPrice - 1) * 100) / daysBetween) * 365;

  return <>{amount.toFixed(actualDecimals)}%</>;
};

function APY(props) {
  const { tokenAddress, decimals, isLegacy } = props;
  const [apy, setAPY] = useState();

  useEffect(() => {
    let isLoading = false;
    const fn = async () => {
      const dao = await fetchDao(tokenAddress);
      if (!isLoading) {
        setAPY(parseFloat(dao.DaoInfo.apy_total_in_usd));
      }
    };
    fn();

    return () => {
      isLoading = true;
    };
  }, []);

  return <>{apy ? <>{apy.toFixed(0)}%</> : <>...</>}</>;
}

export default APY;
