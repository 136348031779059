import React, { useState, useEffect, lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Index from "./Index";
// import { ApolloProvider } from "@apollo/client";
// import { client } from "./graphClient";
import RequireAuth from "./admin/auth/RequireAuth";
import {Provider} from "react-redux";
import store from "./admin/store";
const Dashboard = lazy(() => import( "./Dashboard"));
const Account = lazy(() => import( "./Account"));
const Contacts = lazy(() => import( "./Contacts"));
const AboutUs = lazy(() => import( "./AboutUs"));
const NotFound = lazy(() => import( "./NotFound"));
const CookiePolicy = lazy(() => import( "./CookiePolicy"));
const PrivacyPolicy = lazy(() => import( "./PrivacyPolicy"));
const Admin = lazy(() => import("./admin/Admin"));
const AgentLayout = lazy(() => import("./admin/AgentLayout"));
const Manager = lazy(() => import("./admin/Manager"));
const AgentLoginForm = lazy(() => import("./admin/auth/AgentLoginForm"));

function Main(props) {
  const { connectWeb3, address, web3Global, countryCode } = props;

  return (
    // <ApolloProvider client={client}>
    <Provider store={store}>
      <Router>
        <main className="main">
          <Header
            address={address}
            connectWeb3={connectWeb3}
          />
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
            <Route exact path="/">
              <Index connectWeb3={connectWeb3} web3Global={web3Global} />
              <Footer isCollapsed={true} />
            </Route>
            <Route path="/admin">
              <RequireAuth allowedRoles={['admin']} >
                <Admin />
                <Footer isCollapsed={true} />
              </RequireAuth>
            </Route>
            <Route path="/agents">
              <RequireAuth allowedRoles={['agent']} >
                <AgentLayout />
                <Footer isCollapsed={true} />
              </RequireAuth>
            </Route>
            <Route path="/managers">
              <RequireAuth allowedRoles={['manager']} >
                <Manager />
                <Footer isCollapsed={true} />
              </RequireAuth>
            </Route>
            <Route path="/login">
              <AgentLoginForm />
              <Footer isCollapsed={true} />
            </Route>
            <Route path="/dashboard">
              <Dashboard
                connectWeb3={connectWeb3}
                web3Global={web3Global}
                address={address}
                isAdmin={false}
              />
              <Footer isCollapsed={true} />
            </Route>
            <Route path="/account">
              <Account
                address={address}
                web3Global={web3Global}
                connectWeb3={connectWeb3}
              />
              <Footer isCollapsed={true} />
            </Route>
            <Route path="/cookie">
              <CookiePolicy />
              <Footer isCollapsed={true} />
            </Route>
            <Route path="/privacy">
              <PrivacyPolicy />
              <Footer isCollapsed={true} isBlocked={countryCode} />
            </Route>
            <Route path="/contacts">
              <Contacts />
              <Footer isCollapsed={true} isBlocked={countryCode} />
            </Route>
            <Route path="/about">
              <AboutUs />
              <Footer isCollapsed={true} />
            </Route>
            <Route path="*">
              <NotFound />
              <Footer isCollapsed={true} />
            </Route>
          </Switch>
          </Suspense>
        </main>
      </Router>
    </Provider>
    // </ApolloProvider>
  );
}

export default Main;
